import { device } from "constant";
import styled from "styled-components";

const OurMissionWrapper = styled.div`
        border-radius:10px;
        background-color: ${(props) => props.theme.color.body};
        @media ${device.mobileS}{
                /* margin: 2rem 0 5rem 0; */
        }
        @media ${device.mobileM}{
                margin: 2rem 0 2rem 0;
        }
        @media ${device.mobileL}{
                margin: 3rem 0 6rem 0;
        }
        @media ${device.tablet}{
                margin: 3rem 0 6rem 0;
        }
        @media ${device.laptop}{
                margin: 4rem 0 6rem 0;
        }
        @media ${device.laptopL}{
                margin: 5rem 0 7rem 0;
        }
        @media ${device.desktop}{
                margin: 5rem 0 6rem 0;
        }
`
const ContentWrapper = styled.div`
        @media ${device.mobileS}{
                margin-top: 1rem;
        }
        @media ${device.mobileM}{

                margin-top: 1rem;
        }
        @media ${device.mobileL}{
                margin-top: 1rem;
        }
        @media ${device.tablet}{
                margin-top: 2rem;
         
        }
        @media ${device.laptop}{
                margin-top: 3rem;
        }
        @media ${device.laptopL}{
                margin-top: 3rem;
        
        }
        @media ${device.desktop}{
                margin-top: 3rem;
        }
`
const TopContent =styled.div`

`
const OurMissionHeading= styled.h1`
        display: inline-block;
        position: relative;
        padding: 0 0 12px 0;
        ::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 4px;
        bottom: 0;
        left: 0;
        background-color: ${(props)=> props.theme.color.greenColor};
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        }
        :hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
        }
        @media ${device.mobileS}{
                font-size: 1.4rem;
                margin:0 ;
                padding: 0 0 7px 0;
        }
        @media ${device.mobileM}{
                font-size: 1.4rem;
                margin: 0 ;
                padding: 0 0 8px 0;
        }
        @media ${device.mobileL}{
                font-size:1.4rem;
                margin: 0 ;
                padding: 0 0 10px 0;
        }
        @media ${device.tablet}{
                font-size: 35px; 
        }
        @media ${device.laptop}{
                font-size: 44px; 
        }
        @media ${device.laptopL}{
                font-size: 44px; 
        }
        @media ${device.desktop}{
                margin: 14px 0;
        }

`
const MainOurMissionWraper = styled.div`
        @media ${device.mobileS}{
                margin: 6px 0;
                padding: 1px 0;
        }
        @media ${device.mobileM}{
                margin: 6px 0;
                padding: 2px 0;
        }
        @media ${device.mobileL}{
                margin: 6px 0;
                padding: 2px 0;
        }
        @media ${device.tablet}{
                margin: 8px 0;
                padding: 5px 0;
        }
        @media ${device.laptop}{
                margin: 10px 0;
                padding: 8px 0;
        }
        @media ${device.laptopL}{
                margin: 14px 0;
                padding: 18px 0;
        }
        @media ${device.desktop}{
                margin: 16px 0;
                padding: 20px 0;
        }
`

const BottomContent = styled.div`        
        /* margin-top: 2rem; */

` 

export  { OurMissionWrapper, OurMissionHeading, ContentWrapper, TopContent, BottomContent, MainOurMissionWraper }