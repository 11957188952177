import Heading from "library/Heading";
import { theme } from "library/Theme/theme";
import React from "react";
import { Container } from "react-bootstrap";
import * as El from "./styled.service";
import ApplicationDevAndMentenance from "./Components/ApplicationDevAndMentenance";
import ApplicationSecurityTesting from "./Components/ApplicationSecurityTesting";
import OutSourcing from "./Components/OutSourcing";
import UsTaxing from "./Components/UsTaxing";
import TypingEffect from "components/Animation/TypingEffect";
import useMedia from 'hooks';

const Services = () => {
  const line2 = "What We Offer"
  const isMobile = useMedia()

  return (
    <div>
      <El.HeadingWrapper>
        <div>
        <TypingEffect  line2={line2} noBr={false} noQuotation={false}/>
        </div>
      </El.HeadingWrapper>
      <El.TextContent>
        <Heading
            style={{
              fontSize:isMobile ? "35px" :"45px",
              fontFamily: "Barlow",
              fontWeight: "800",
              textAlign:"center"
            }}
          >
            <span style={{ color: theme.color.bgColor }}>What </span>
            <span style={{ color: theme.color.greenColor }}>
              {" "}
              We Offer{" "}
            </span>{" "}
          </Heading>
      </El.TextContent>
      <div
        style={{
          backgroundColor: theme.color.greyColor,
          padding: "2rem 0",
          margin: "40px 0",
        }}
      >
        <Container>
          <UsTaxing/>
          <OutSourcing/>
        </Container>
      </div>
      <Container>
          <ApplicationDevAndMentenance />  
          <ApplicationSecurityTesting />
      </Container>
   
      
    </div>
  );
};

export default Services;
