import { useState, useEffect } from 'react';

const default_query = `(max-width: ${"993px"})`;

export default function useMedia(query=default_query) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) setMatches(media.matches);
        const listener = () => setMatches(media.matches);
        try {
            //Chrome and Firefox
            media.addEventListener("change", listener);
            return () => media.removeEventListener("change", listener);
        }
        catch (error) {
            //Safari
            try {
                media.addListener('change',listener);
                return () => media.removeListener('change',listener);
            }
            catch (e1) {
                console.error(e1);
            }
        }
    }, [matches, query]);
    return matches;
}