import Bounce from "components/Animation/Bounce";
import Heading from "library/Heading";
import Image from "library/Image";
import { theme } from "library/Theme/theme";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getImagePath } from "utils";
import FormComponent from "./Components";
import * as El from './styled.contact'
import  useMedia  from 'hooks';
const ContactUs = () => {
  const isMobile = useMedia()
  return (
    <Container>
      <div></div>
      <Row>
        <Col className="d-none d-md-block d-lg-block">
          <Bounce>

          <El.ImageWrapper>
            <Image src={getImagePath("/5138237.jpg")} style={{height:"500px",width:"100%",marginTop:"3rem"}} />
          </El.ImageWrapper>
          </Bounce>

        </Col>
        <Col>
          <div style={{ marginTop: isMobile ? "12px" : "120px",display:"flex",alignItems:"center" }}>
            <Heading
              style={{ fontSize: isMobile ? "25px": "40px",
              fontFamily: "Barlow",
              fontWeight: "600"}}
            >
              Lets Talk{" "}
              <span style={{ color: theme.color.greenColor }}>Business !</span>
            </Heading>
            <div>
              <Image src={getImagePath("/contact.jpeg")} style={{ height: "50px", width: "50px", margin: "0 1rem" }} />          
            </div>
          </div>
          <FormComponent />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
