import styled from "styled-components";

const FooterWrapper = styled.div`
   background-color: ${({theme})=> theme.color.bgColor};
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding:19px 3rem; 
`
const FooterMainWrapper = styled.div`
   margin-top: 12px;
`
const FooterContent = styled.div`
   background-color: ${({theme})=> theme.color.bgColor};
   opacity: .9;
`
const FooterLeftContent = styled.div`
  a{
   display: flex;
    align-items: center;
    margin-top: 20px;
  }
   h1{
      font-family: "Josefin Slab", sans-serif;
      margin-left: 15px;
   }
`
const FooterMiddleContent = styled.div`
    color:white;
    ul{
      padding: 12px;
      li{
         margin: 0 0 12px  0;
      }
    }
`
const FooteRightContent = styled.div`
    color:white;
`
export { FooterWrapper,FooterLeftContent,FooterContent,FooterMiddleContent,FooteRightContent,FooterMainWrapper  }