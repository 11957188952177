import React,{ useState ,useEffect, useRef} from 'react'
import Image from 'library/Image'
import { getImagePath } from 'utils';
import { routeConstants } from 'constant';
import LinkTag from 'library/Link';
import * as El from './styled.navbar'
import useMedia from 'hooks';

const Navbar = () => {
  const [sidebar,setSidebar] = useState(false)
  const sideRef  = useRef()
  const [y, ] = useState(window.scrollY)
  
  useEffect(()=>{
    if(sidebar){

    function handleClickOutside(event) {
      if (sideRef.current && !sideRef.current.contains(event.target)) {
         setSidebar(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
   }
  },[sideRef,sidebar])

  useEffect(()=>{
    if(sidebar){
      const handleNavigation=(e)=>{
        setSidebar(false)        
      }
      window.addEventListener("scroll", (e) => handleNavigation(e));
      return ()=> window.addEventListener("scroll", (e) => handleNavigation(e));
    }
  },[y,sidebar])
  const isMobile = useMedia()
  const style = isMobile ? {height: "40px",width:"40px",marginTop:"9px"} : {height: "60px",width:"60px"}
  return (
    <El.NavbarWrapper>
          <El.NavbarLeftWrapper>
  
              <El.ImageWrapper>
                <LinkTag route={`${routeConstants.HOME}`}>
                  <Image src={getImagePath('/logo.png')} style={style} />
                </LinkTag>
              </El.ImageWrapper>
        
              <El.LogoName>
                <LinkTag route={`${routeConstants.HOME}`}>
                    Venatic Consulting
                </LinkTag>
              </El.LogoName>
          </El.NavbarLeftWrapper>

          {/* hamburger start*/}
        
           {!sidebar &&
            <El.NavbarHamburger onClick={()=>setSidebar(true)}>
               <El.HambergurWrapper>
               <Image src={getImagePath('/hamburger.svg')} style={{height: "30px",width:"30px"}}/>
                </El.HambergurWrapper>
            </El.NavbarHamburger>   
          }
          {sidebar  &&
            <El.SideBarWrapper ref={sideRef}>
              <El.NavbarHamburger onClick={()=>setSidebar(false)}>

              <El.HambergurWrapperCross> 
                   <Image src={getImagePath('/cross.svg')} style={{height: "25px",width:"25px",marginLeft:"7px"}}/>    
              </El.HambergurWrapperCross>
              </El.NavbarHamburger>

             <El.SidebarLinkWrapper onClick={()=>setSidebar(false)}>
              <LinkTag route={`${routeConstants.HOME}`}>
                Home
              </LinkTag>
             </El.SidebarLinkWrapper>
              <El.SidebarLinkWrapper onClick={()=>setSidebar(false)}>
                <LinkTag route={routeConstants.SERVICES}>
                Services
              </LinkTag>
            </El.SidebarLinkWrapper>

            <El.SidebarLinkWrapper onClick={()=>setSidebar(false)}>
                <LinkTag route={routeConstants.SERVICES}>
                  Our Clients
                </LinkTag>
            </El.SidebarLinkWrapper>

              <El.SidebarLinkWrapper onClick={()=>setSidebar(false)}>
                <LinkTag route={`${routeConstants.CONTACT_US}`}>
                 Contact us
               </LinkTag>
              </El.SidebarLinkWrapper>
            </El.SideBarWrapper>
            
          }
          {/* hamburger end*/}
          

          <El.NavbarRightWrapper>
            <El.LinkTagWrapper>
							<LinkTag route={`${routeConstants.HOME}`}>
                Home
              </LinkTag>
            </El.LinkTagWrapper>

            <El.LinkTagWrapper>
            <LinkTag route={routeConstants.SERVICES}>
               Services
            </LinkTag>
            </El.LinkTagWrapper>
            
            <El.LinkTagWrapper>
							<LinkTag route="#">
              Our Clients
              </LinkTag>
            </El.LinkTagWrapper>
            <El.LinkTagWrapper>
							<LinkTag route={`${routeConstants.CONTACT_US}`}>
                Contact us
              </LinkTag>
            </El.LinkTagWrapper>
        </El.NavbarRightWrapper>  
    </El.NavbarWrapper>
  )
}

export default Navbar
