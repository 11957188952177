import React from 'react'
import { motion } from 'framer-motion';
import * as El from './styled.typingeffect'

const TypingEffect = ({ line1, line2,noBr, noQuotation}) => {
    const sentence = {
         hidden : {opacity: 1},
         visible :{
            opacity:1,
            transition:{
                delay :0.5,
                staggerChildren:0.08
            }
         }
    }
    const letter = {
        hidden: {opacity : 0 ,y: 50},
        visible :{
            opacity:1,
            y: 0
        }
    }

    return (
    <div>
        <motion.h3  variants={sentence} initial="hidden" animate="visible">
         { noQuotation && <El.RightQuotation>&#8220; </El.RightQuotation> }
            {line1?.split("").map((char,index) =>{
                return <motion.span key={char+"-"+index} variants={letter}>
                        <El.CarouselContentH1>{char}</El.CarouselContentH1>
                </motion.span>
            })}
           {noBr && <br/> } 
            {line2?.split("").map((char,index) =>{
                return <motion.span key={char+"-"+index} variants={letter}>    
                 <El.CarouselContentSpan>{char}</El.CarouselContentSpan>
                </motion.span>
            })}
           {noQuotation &&  <El.LeftQuotation>&#8221;</El.LeftQuotation> }

        </motion.h3>
    </div>
  )
}
TypingEffect.defaultProps = {
    noBr: true,
    noQuotation:true
}
export default TypingEffect
