import styled from "styled-components";
import { device } from 'constant';

const ServiceContentWrapper = styled.div`
 background: url('');
`

const ServiceTesting = styled.div`
    
    @media ${device.mobileS}{
        display: none;
    }
    @media ${device.mobileM}{
        display: none;
    }
    @media ${device.mobileL}{
        display: none;
    }
    @media ${device.tablet}{
        display: none;
    }

    @media ${device.laptop}{
        display: block;
        height:50%;
        width:50%;
        float: right;
        shape-outside: circle(70%);
    }

    @media ${device.laptopL}{
        display: block;
        height:50%;
        width:50%;
        float: right;
        shape-outside: circle(70%);
    }

    @media ${device.desktop}{
        display: block;
        height:50%;
        width:50%;
        float: right;
        shape-outside: circle(70%);
    }
    @media ${device.desktopL}{
        display: block;
        height:50%;
        width:50%;
        float: right;
        shape-outside: circle(70%);
    }
`

const AppSecParaghraph = styled.p`
    color:${(props)=> props.theme.color.blackColor};
    font-weight: 200;
    letter-spacing: 1px;
    margin: 30px 0;
    @media ${device.laptop}{
    font-size: 19px;
    }
    @media ${device.laptopL}{
    font-size: 19px;
    }
    @media ${device.desktop}{
    font-size: 19px;
        
    }
    @media ${device.desktopL}{
    font-size: 19px;
    }
`

export  { ServiceContentWrapper, ServiceTesting , AppSecParaghraph }
