import Image from "library/Image";
import React from "react";
import Slider from "react-slick";
import { getImagePath } from "utils";
import * as El from "./carousel.styled";
import Button from "library/Button";
import { useNavigate } from "react-router-dom";
import { routeConstants } from "constant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TypingEffect from "components/Animation/TypingEffect";
import "./index.css";

const Carousel = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    arrows: true,
    slidesToShow: 1,
    autoplay:true,
    useTransform: false,
  };
  const navigate = useNavigate();

  const handleRedirect = (path) => {
    navigate(path);
  };

  const line1= "We Create a Path for"
  const line2 = "Consistent Growth"

  const secondtext1 = "Check out our"
  const secondText2 = "SERVICES"
 
  return (
    <Slider {...settings} style={{ overflow: "hidden"}}>
      <div>
        <El.WrapperImage>
          <Image src={getImagePath("/carousel-2.jpg")}  style={{width:"100%"}}/>
          <El.CarouselContentWrapper>
            <TypingEffect line1={line1} line2={line2}/>
            <El.ButtonWrapper>

              <Button  style={{fontSize:"14px",fontWeight:700}} onClick={() => handleRedirect(routeConstants.CONTACT_US)}>
                {" "}
                Contact us
            </Button>
            </El.ButtonWrapper>

          </El.CarouselContentWrapper>
        </El.WrapperImage>
      </div>
      <div>
        <El.WrapperImage>
          <Image src={getImagePath("/carousel-1.jpg")}   style={{height:"auto",width:"100%"}}/>
          <El.CarouselContentWrapper>
            <TypingEffect line1={secondtext1} line2={secondText2}/>
              <El.ButtonWrapper>
              <Button  style={{fontSize:"14px",fontWeight:700}} onClick={() => handleRedirect(routeConstants.SERVICES)}>
                Explore
              </Button>
            </El.ButtonWrapper>
          </El.CarouselContentWrapper>
        </El.WrapperImage>
      </div>
    </Slider>
  );
};

export default Carousel;
