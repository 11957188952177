import React from 'react'
import * as El from './styled.button'

const Button = (props) => {
  const { children } = props
  return <El.CCButton {...props}> {children}</El.CCButton>
}
Button.defaultProps = {
    primary: true
}

export default Button
