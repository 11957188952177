import React from 'react'
import Banner from './Components/Banner'
import Container from 'react-bootstrap/Container';
import OurMission from './Components/OurMission'
import About from './Components/About';
import OurApproach from './Components/OurApproach';
import Experties from './Components/Experties';
import * as El from './styled.home'

const Home = () => {
  return (
    <div>
        <Banner/>
      <Container>
      </Container>
      <El.HomeOurMissionWrapper>
        <Container>
            <OurMission/>
        </Container>
      </El.HomeOurMissionWrapper>
      <Container>
      <About/>
      <OurApproach/>
      <Experties/>
      </Container>
    </div>
  )
}

export default Home
