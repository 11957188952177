import { device } from "constant";
import styled ,{ css }from "styled-components";
const forDesktopScreen = css`
   background-color: hsla(0, 0%, 0%, 0.3); ;
   position: fixed;
   width: 100%;
   top:0;
   z-index: 9999;
` 
const NavbarWrapper = styled.div`
   display: flex;
   
   @import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@600&display=swap');
   align-items: center;
   justify-content: space-between;
   /* position: fixed;
   width: 100%;
   top: 0;
   z-index: 9999;  Fixed NavBar*/
   @media ${device.mobileS} {
        padding:3px 1rem ;
        
        background-color: ${({theme})=> theme.color.bgColor}; 
   }
   @media ${device.mobileL} {
        padding:3px 1rem ;
        background-color: ${({theme})=> theme.color.bgColor}; 
   }

   @media ${device.laptop} {
    padding:10px 8rem ;
    ${forDesktopScreen}
      :hover{
            background-color: ${({theme})=> theme.color.bgColor};
            cursor: pointer;
            opacity: 1;
      } 
   }
`
const NavbarLeftWrapper = styled.div`
    display: flex;
    align-items: center;
`

const NavbarRightWrapper = styled.div`
     @media ${device.mobileS}{
        display: none;
     }
     @media ${device.laptop}{
        display: flex;
        align-items: center;
     }
`

const LogoName = styled.h2`
    margin-left: 15px;
    font-weight: 600;
    a{
    font-family: "Josefin Slab", sans-serif;
    }
    @media ${device.mobileS}{
        display: none;
    }
    @media ${device.mobileL}{
        display: block;
    }
`

const LinkTagWrapper = styled.div`
     margin-right: 40px;
     a{
        font-size:20px;
     }
`

// for Image 

const ImageWrapper = styled.div`    
    height: 60px; //
    width:60px;
`
const TooltipWrapper = styled.div`
    &:hover{
        background-color:${(props) => props.theme.color.greenColor};
        border-radius: 8px;
    }
`
// hamburger css
const  NavbarHamburger= styled.div`

`
const HambergurWrapper = styled.div`
     /* height: 70px;
     width: 1.6rem;
     cursor: pointer; */
     @media ${device.laptop}{
        display: none;
     }

`
const HambergurWrapperCross = styled.div`
    cursor: pointer;
     height: 30px;
     width: 48px;
     margin-top:30px;
     margin-left: 10px;
     @media ${device.laptop}{
        display: none;
    }
`
//Sidebar 
const SideBarWrapper = styled.div`
        position:absolute;
        z-index:9999;
        top:0;
        right:0;
        background-color:${(props)=> props.theme.color.blackColor};
        height:1000%;
        width:41%;
        @media ${device.laptop}{
            display: none;
        }
`
const SidebarLinkWrapper = styled.div`
    padding:20px
`

export {NavbarHamburger,SideBarWrapper,SidebarLinkWrapper, HambergurWrapperCross,HambergurWrapper,ImageWrapper, NavbarLeftWrapper, NavbarRightWrapper, NavbarWrapper, LogoName, LinkTagWrapper, TooltipWrapper }