import { device } from "constant";
import styled from "styled-components";

const HeadingWrapper = styled.div`
    text-align :center ;
    background-color: ${(props)=> props.theme.color.bgColor};
    padding: 100px 0;
  @media ${device.mobileS}{
    display: none;
  }
  @media ${device.mobileM}{
    display: none;
  }

  @media ${device.mobileL}{
    display: none;
  }

  @media ${device.laptop}{
    display: block;
  }
  @media ${device.laptopL}{
    display: block;
  }
  @media ${device.desktop}{
    display: block;
  }

  @media ${device.desktopL}{
    display: block;
  }
`
const TextContent = styled.div`
 @media ${device.mobileS}{
    display: block;
  }
  @media ${device.mobileM}{
    display: block;
  }

  @media ${device.mobileL}{
    display: block;
  }
  @media ${device.tablet}{
    display: block;
  }

  @media ${device.laptop}{
    display: none;
  }
  @media ${device.laptopL}{
    display: none;
  }
  @media ${device.desktop}{
    display: none;
  }

  @media ${device.desktopL}{
    display: none;
  }
`

const ImageWrapper = styled.div`
    height: 70.5%;
    width: 100%;
`


export { HeadingWrapper, ImageWrapper, TextContent }