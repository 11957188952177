import React from 'react'
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import  Heading  from 'library/Heading';
import { theme } from 'library/Theme/theme';
import Image from 'library/Image';
import { getImagePath } from 'utils';
import HoverZoomIn from 'components/Animation/HoverZoomIn';
import ScrollView from 'components/Animation/ScrollView';
import * as El from './styled.outsourcing'
import  useMedia  from 'hooks';

const OutSourcing = () => {
  const isMobile = useMedia()
  const applyStyle = isMobile ?
  {
    fontSize: "27px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } : {
    fontSize: "50px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } 
  return (
    <div>
         <Row>
            <Col xs={12} md={12} lg={6} xxl={6}>

              <div di="outsourcing">
                <Heading
                  style={applyStyle}
                >
                  Out Sourcing
                </Heading>
                <div>
                <ScrollView>
                  <El.OutSourcongWrapper>
                  <El.OutSourcingHeading>
                    Staffing / Recruiting
                  </El.OutSourcingHeading>
                    <El.OutSourcingParaghraph>
                      Recruiting specialists for all industries and job types,
                      from entry level positions to high-level management jobs.
                      We are ready work with large and small businesses across
                      the country, so there is always a good fit for you.
                    </El.OutSourcingParaghraph>
                  <El.OutSourcingHeading>
                      Training on various platforms </El.OutSourcingHeading>
                    <El.OutSourcingParaghraph>
                      Training the freshers with
                      the required skills from the industry experts
                    </El.OutSourcingParaghraph>
                  <El.OutSourcingHeading>
                    BPO
                  </El.OutSourcingHeading>
                    <El.OutSourcingParaghraph>
                      We empower individuals and companies across the
                      world. <br/><br/> <i style={{color:theme.color.purpleColor}}>*Learn from some of our best practices and be part
                      of our success story.</i>
                    </El.OutSourcingParaghraph>
                  </El.OutSourcongWrapper>
              </ScrollView>
                </div> 
              </div>
            </Col>
            <Col xs={12} md={12} lg={6} xxl={6} className="d-none d-lg-block">
              <HoverZoomIn scale={1.2}>
                <ScrollView>
                <div style={{marginTop:"14rem", marginLeft:"5rem", objectFit:"cover"}}>
                <Image src={getImagePath('/Outsourcing.jpeg')} style={{borderRadius:"50%",height:"70%",width:"100%"}} />
                </div>
              </ScrollView>
            </HoverZoomIn>          
            </Col>
          </Row>
    </div>
  )
}

export default OutSourcing
