import styled from "styled-components"
import { device } from 'constant';

const CouruselData = styled.span`
        color: ${(props)=> props.theme.color.body} ;
        font-family: Barlow;
        font-weight: 500;
`
const CarouselContentH1 =styled(CouruselData)`
        @media ${device.mobileS} {
          font-size: 1rem;  
        }
        @media ${device.mobileM} {
          font-size: 1.3rem;  
        }
        @media ${device.mobileL} {
          font-size: 1.6rem;  
        }
        @media ${device.tablet} {
          font-size: 1.8rem;  
        }
        @media ${device.laptop}{
          font-size: 4.5rem;
        }
        @media ${device.desktop}{
          font-size: 4.8rem;
        }
        @media ${device.desktopL}{
          font-size: 5rem;
        }
`
const CarouselContentSpan = styled.span`  
      color: ${(props)=> props.theme.color.greenColor} ;
      font-family:Barlow;
      margin-top:3rem;
      font-size: 5rem;
      @media ${device.mobileS}{
          font-size: 1.2rem;
      }
      @media ${device.mobileM}{
        font-size: 1.8rem;
      }
      @media ${device.mobileL}{
        font-size: 2.7rem;
      }
      @media ${device.tablet}{
        font-size: 3rem;
      }
      @media ${device.desktop}{
        font-size: 5rem;
      }
      @media ${device.laptopL}{
        font-size: 5rem;
      }
      @media ${device.desktopL}{
        font-size: 5rem;
      }
      @media ${device.laptop}{
        font-size: 5rem;
      }
` 

const RightQuotation= styled.span`
        color: ${(props)=> props.theme.color.greenColor} ;
        font-family:Barlow;
        font-weight:500;
        font-size: 5rem;
        @media ${device.mobileS}{
          font-size: .9rem;
        }
        @media ${device.mobileM}{
          font-size: 1rem;
        }
        @media ${device.mobileL}{
          font-size: 1.2rem;
        }
        @media ${device.tablet}{
          font-size: 1.4rem;
        }
        @media ${device.tablet}{
          font-size: 2rem;
        }
        @media ${device.desktop}{
          font-size: 5rem;
        }
        @media ${device.laptopL}{
          font-size: 5rem;
        }
        @media ${device.desktopL}{
          font-size: 5rem;
        }
        @media ${device.laptop}{
          font-size: 5rem;
        }
`         
const LeftQuotation = styled.span`
        color: ${(props)=> props.theme.color.body} ;
        font-family:Barlow;
        font-weight:500;
        font-size: 5rem;
        @media ${device.mobileS}{
          font-size: .9rem;
        }
        @media ${device.mobileM}{
          font-size: 1rem;
        }
        @media ${device.mobileL}{
          font-size: 1.2rem;
        }
        @media ${device.tablet}{
          font-size: 1.4rem;
        }
        @media ${device.tablet}{
          font-size: 2rem;
        }
        @media ${device.desktop}{
          font-size: 5rem;
        }
        @media ${device.laptopL}{
          font-size: 5rem;
        }
        @media ${device.desktopL}{
          font-size: 5rem;
        }
        @media ${device.laptop}{
          font-size: 5rem;
        }
`  


export  {CarouselContentH1, CarouselContentSpan, RightQuotation,LeftQuotation,}