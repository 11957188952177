import React from "react";
import { motion } from "framer-motion";

const HoverZoomIn = (props) => {
  const { children,scale } = props;
  return (
    <motion.div
      className="box"
      /**
       * Setting the initial keyframe to "null" will use
       * the current value to allow for interruptable keyframes.
       */
      whileHover={{ scale: scale, rotate: 0 }}
      whileTap={{ scale: 0.8, rotate: 0, borderRadius: "100%" }}
    >
      {children}
    </motion.div>
  );
};

export default HoverZoomIn;
