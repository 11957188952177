import { device } from "constant";
import styled from "styled-components";

const BottomADMContentWrapper = styled.div`
  background-color: ${(props) => props.theme.color.greyColor};
  border-radius: 18px;
  padding: 18px;
`;

const BottomADMContent = styled.p`
  @media ${device.mobileS}{
   font-weight: 600;
   font-size: 20px;
  }
   @media ${device.mobileM}{
    font-weight: 600;
  font-size: 20px;
  }
   @media ${device.mobileL}{
   font-weight: 600;
  font-size: 20px; 
  }
   @media ${device.tablet}{
    font-weight: 600;
    font-size: 23px;
  }
   @media ${device.laptop}{
    font-weight: 600;
  font-size: 30px;
  }
   @media ${device.laptopL}{
    font-weight: 600;
  font-size: 30px;
  }
   @media ${device.desktop}{
    font-weight: 600;
  font-size: 30px;
  }

   @media ${device.desktopL}{
    font-weight: 600;
    font-size: 30px;
  }
`;

const BottomADMContentData = styled.p`
 
  @media ${device.laptopL}{
      font-weight: 500; 
      font-size: 20px;
    }
  @media ${device.laptop}{
      font-weight: 500; 
      font-size: 20px;
    }
    @media ${device.desktop}{
      font-weight: 500; 
      font-size: 20px;
    }

    @media ${device.desktopL}{
      font-weight: 500; 
      font-size: 20px;
    }
`;
const AppDevParagraph = styled.p`
    @media ${device.mobileS}{
     font-size: 16px;
      font-weight: 200;
      letter-spacing: 1px;
      margin-top: 12px;
    }
    @media ${device.mobileM}{
     font-size: 16px;
      font-weight: 200;
      margin-top: 12px;

      letter-spacing: 1px; 
    }
    @media ${device.mobileL}{
      font-size: 16px;
      font-weight: 200;
      margin-top: 12px;

      letter-spacing: 1px; 
    }
    @media ${device.tablet}{
      font-size: 16px;
      margin-top: 12px;
      font-weight: 200;
      letter-spacing: 1px;
    }
    @media ${device.laptop}{
      font-size: 19px;
    font-weight: 200;
    letter-spacing: 1px;
    }
    @media ${device.laptopL}{
      font-size: 19px;
    font-weight: 200;
    letter-spacing: 1px;
    }
    @media ${device.desktop}{
      font-size: 19px;
    font-weight: 200;
    letter-spacing: 1px;
    }
    @media ${device.desktopL}{
     font-size: 19px;
      font-weight: 200;
      letter-spacing: 1px; 
    }
`;

export {
  BottomADMContentWrapper,
  BottomADMContent,
  BottomADMContentData,
  AppDevParagraph,
};
