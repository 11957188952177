import { device } from "constant";
import styled from "styled-components";

const OutSourcongWrapper = styled.div`
  background-color: ${(props) => props.theme.color.greenColor};
  padding: 2rem;
  border-radius: 19px;
`;
const OutSourcingHeading = styled.h1`
  color: ${(props) => props.theme.color.blackColor};
  letter-spacing: 1px;
  margin: 8px 0;
  @media ${device.mobileS}{
    font-size: 20px;
  }
  @media ${device.mobileM}{
  font-size: 20px;
  }
  @media ${device.mobileL}{
    
  font-size: 20px;
  }
  @media ${device.tablet}{
  font-size: 24px;
  }
  @media ${device.laptop}{
  font-size: 27px;
  }
  @media ${device.laptopL}{
    
  font-size: 27px;
  }
  @media ${device.desktop}{
    
  font-size: 27px;
  }
  @media ${device.desktopL}{
    
  font-size: 27px;
  }
`;

const OutSourcingSecondHeading = styled.h1`
  margin: 8px 0;
`;
const OutSourcingParaghraph = styled.p`
  color: ${(props) => props.theme.color.body};
  font-weight: 400;
  letter-spacing: 1px;
  @media ${device.mobileS}{
  font-size: 15px;
  }
  @media ${device.mobileM}{
  font-size: 15px;
    
  }
  @media ${device.mobileL}{
  font-size: 15px;
  }
  @media ${device.tablet}{
  font-size: 19px;
  }
  @media ${device.laptop}{
  font-size: 19px;
    
  }
  @media ${device.laptopL}{
  font-size: 19px;
    
  }
  @media ${device.desktop}{
  font-size: 19px;
    
  }
  @media ${device.desktopL}{
  font-size: 19px;
    
  }
`;

export {
    OutSourcongWrapper,
    OutSourcingHeading,
    OutSourcingParaghraph,
    OutSourcingSecondHeading,
};
