import { device } from "constant";
import styled  from "styled-components";

const USParagraph = styled.p`
    color: ${(props)=> props.theme.color.blackColor};
    font-weight: 200;
    letter-spacing: 1px;
    margin: 30px 0;
     @media ${device.tablet}{
    font-size: 19px;
        
    }
     @media ${device.laptop}{
    font-size: 19px;
    }
     @media ${device.laptopL}{
    font-size: 19px;
        
    }
     @media ${device.desktop}{
    font-size: 19px;
        
    }
    @media ${device.desktopL}{
    font-size: 19px;
        
    }
`
export { USParagraph }