import Button from "library/Button";
import Input from "library/Input";
import Textarea from "library/Textarea";
import React, { useState } from "react";
import validator from "validator";
import { Row, Col } from "react-bootstrap";
import * as El from "./styled.formcomponent";
import EaseOut from "components/Animation/EaseOut";
import emailjs from 'emailjs-com';
import { SERVICE_ID, TEMPLATE_ID, USER_ID } from "constant";

const FormComponent = () => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phone: "",
  });
  const [messageResponse, setMessageResponse ] = useState("")

  const [errObj, setErrObj] = useState({});

  let errors = {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const runValidator = () => {
    if (state?.firstName.length === 0) {
      errors.firstName = "First Name is required *";
    }
    if (state?.lastName.length === 0) {
      errors.lastName = "Last Name is required *";
    }
    if (state?.email.length === 0) {
      errors.email = "Email is required *";
    } else if (!validator.isEmail(state?.email)) {
      errors.email = "Email is not valid *";
    }
    if (state?.message?.length === 0) {
      errors.message = "Message is required *";
    }
    if (state?.message?.length > 0 && state?.message?.length < 25) {
      errors.message = "Message must be greater than 25 character*";
    }
    if (state?.phone?.length === 0) {
      errors.phone = "Phone Number is required *";
    } else if (!validator.isMobilePhone(state?.phone, ["en-IN"])) {
      errors.phone = "Enter a valid phone number *";
    }
  };
  

  const handleSubmit = () => {
    runValidator();
    if (Object.keys(errors).length === 0) {
      setErrObj({});
      const payload = {
        from_name : `${state?.firstName} ${state?.lastName} - ${state?.email} ${state.phone} `,
        to_name: "Surya",
        message:state?.message
      }
      emailjs.send(SERVICE_ID, TEMPLATE_ID, payload, USER_ID)
      .then((result) => {
         setMessageResponse("Thank you.Successfully submitted")
        setState({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phone: "",
        });
        setTimeout(()=>{
          setMessageResponse("")
       },[3000])
      }, (error) => {
        setMessageResponse("Thank you.Successfully submitted")
        console.log(error)
      });
    } else {
      setErrObj(errors);
    }
  };
  return (
    <El.ContactUsWrapper>
      <Row>
        <Col xs={12} md={12} lg={6} xxl={6}>
          <EaseOut>
            <div>
              <Input
                type="text"
                value={state.firstName}
                onChange={handleChange}
                name="firstName"
                required
                placeholder="Enter First Name"
                error={errObj?.firstName?.length > 0}
              />
              <p style={{ color: "red" }}>
                {errObj?.firstName && errObj?.firstName}
              </p>
            </div>
          </EaseOut>
        </Col>
        <Col xs={12} md={12} lg={6} xxl={6}>
          <EaseOut>
            <div>
              <Input
                type="text"
                value={state.lastName}
                onChange={handleChange}
                name="lastName"
                required
                error={errObj?.lastName?.length > 0}
                placeholder="Enter Last Name"
              />
              <p style={{ color: "red" }}>
                {errObj?.lastName && errObj?.lastName}
              </p>
            </div>
          </EaseOut>
        </Col>
      </Row>
      <EaseOut>
        <div>
          <Input
            type="email"
            value={state.email}
            onChange={handleChange}
            name="email"
            required
            error={errObj?.email?.length > 0}
            placeholder="Enter Email"
          />
          <p style={{ color: "red" }}>{errObj?.email && errObj?.email}</p>
        </div>
      </EaseOut>
      <EaseOut>
        <div>
          <Input
            type="text"
            value={state.phone}
            onChange={handleChange}
            name="phone"
            required
            error={errObj?.phone?.length > 0}
            pattern="[1-9]{1}[0-9]{9}"
            placeholder="Enter Phone Number"
          />
          <p style={{ color: "red" }}>{errObj?.phone && errObj?.phone}</p>
        </div>
      </EaseOut>
      <EaseOut>
        <div>
          <Textarea
            rows={3}
            value={state.message}
            cols={12}
            onChange={handleChange}
            name="message"
            required
            error={errObj?.message?.length > 0}
            placeholder="Enter Message"
          />
          <p style={{ color: "red" }}>{errObj?.message && errObj?.message}</p>
        </div>
      </EaseOut>
      <EaseOut>
        <Button style={{fontSize:"20px",fontWeight:"600"}} onClick={handleSubmit}>Submit</Button>
        <p style={{ color: "green" }}>{messageResponse && messageResponse}</p>
      </EaseOut>
    </El.ContactUsWrapper>
  );
};

export default FormComponent;
