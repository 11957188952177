import React from 'react'
import * as El from './styled.heading'

const Heading = (props) => {
  const { children } = props
  return (
    <El.Heading {...props}>{children}</El.Heading>
  )
}

export default Heading
