import React from "react";
import { theme } from "library/Theme/theme";
import { Row, Col } from "react-bootstrap";
import * as El from "./styled.experties";
import ScrollView from "components/Animation/ScrollView";
import { OurMissionHeading } from "../OurMission/styled.ourmission";
import  useMedia  from 'hooks';

const Experties = () => {
  const isMobile = useMedia()
  return (
    <div>
        <OurMissionHeading >
        Engagement <span style={{ color: theme.color.greenColor }}>Models </span>{" "}
        </OurMissionHeading>

      <El.ExpertiesMainWrapper>
      <div>
        <Row>
          <Col xl={12} md={12} lg={6} xxl={6}>
            <ScrollView>

            <El.ExpertiesLeftWrapper>
              <El.ExpertiesLeftContent>
                <div style={{padding:"0", margin:"0"}}>
                  <h2>Partnering</h2>
                  <p>
                    Long term mobilization plans with the client overseas and
                    venatic will meticulously onboard and maintain staff &
                    project as in client’s charter and roadmap.
                  </p>
                </div>
              </El.ExpertiesLeftContent>
            </El.ExpertiesLeftWrapper>
            </ScrollView>

          </Col>
          <Col xs={12} md={12} lg={6} xxl={6}>
            <El.ExpertiesRightWrapper>
              <ScrollView>

              <div style={{padding:"0",margin:"12px"}}>
                <h2>Project</h2>
                <p style={{ fontSize : isMobile ?  "17px" :" 20px"}}>
                  Venatic can help in deploying a team for a particular project
                  on a contract base
                </p>
              </div>
              </ScrollView>
            <ScrollView >
              <div style={{padding:"0",margin:"0"}}>
                        <h2> ADHOC </h2>
                      <p style={{ fontSize : isMobile ?  "17px" :" 20px"}}>
                          Managing On hold projects on emergency or assisting in
                          requirement of resources.
                        </p>
                      </div>
            </ScrollView>

            </El.ExpertiesRightWrapper>
          </Col>
        </Row>
      </div>
    </El.ExpertiesMainWrapper>

    </div>

  );
};

export default Experties;
