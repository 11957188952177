
import React from 'react'
import {
    motion,
  } from 'framer-motion';
  import { useInView } from 'react-intersection-observer';

const ScrollView = (props) => {
    const {children}= props
    const [ref, inView] = useInView({
        /* Optional options */
        threshold: 0.5,
        triggerOnce: false
      });

  const variants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: {
      opacity: 0,
      scale: 0.65,
      y: 50
    }
  };
  
    return (
    <motion.div
        animate={inView ? 'visible' : 'hidden'}
        variants={variants}
        transition={{ duration: 2, ease: 'easeOut' }}
        ref={ref}
    >   
    {children}
    </motion.div>
  )
}

export default ScrollView
