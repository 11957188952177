import Image from 'library/Image';
import React from 'react'
import * as El from  './styled.vccard'

const VCCard = (props) => {
  const { text ,header,src }= props
  return (
    <El.CardWrapper>
          <El.CardImageWrapper >
            <Image src={src} style={{  height: '90px',width: '90px'}}/>
          </El.CardImageWrapper>
          <El.CardTitleWrapper>
            <El.CardTitle>{header}</El.CardTitle>
            <El.CardContent>
              <p>{text}</p>
            </El.CardContent>
          </El.CardTitleWrapper>
    </El.CardWrapper>
  )
}

export default VCCard
