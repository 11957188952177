import { device } from "constant";
import styled from "styled-components";

const MainButtom = styled.button`
  outline: auto;
  border: none;
  border-radius: 5px;
  color: ${(props) => (props.primary ? "white" : "black")};
  background-color: ${(props) =>
    props.primary ? props.theme.color.bgColor : ""};
`;
const CCButton = styled(MainButtom)`
  @media ${device.mobileS} {
    padding: 6px 13px;
    font-size: 11px;
  }
  @media ${device.mobileM} {
    padding: 7px 15px;
    font-size: 14px;
  }
  @media ${device.mobileL} {
    padding: 10px 15px;
    font-size: 14px;
  }
  @media ${device.tablet} {
    padding: 10px 13px;
    font-size: 14px;
  }
  @media ${device.laptop} {
    padding: 12px 15px;
    font-size: 14px;
  }
  @media ${device.desktop} {
    padding: 13px 16px;
    font-size: 14px;
  }
  @media ${device.desktopL} {
    padding: 14px 18px;
    font-size: 18px;
  }
`;

export { CCButton };
