import { theme } from "library/Theme/theme";
import Accordion from "react-bootstrap/Accordion";

const VCAccordion = (props) => {
  const { idx, heading, text , pricing } = props;
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey={idx}>
        <Accordion.Header>
          <p
            style={{
              color: theme.color.blackColor,
              fontSize: "19px",
              fontWeight: "700",
              letterSpacing: "1px",
              margin: "9px 0",
            }}
          >
            {heading}
          </p>
        </Accordion.Header>
        <Accordion.Body>
          {pricing?.length >0 
           ?
           pricing?.map((items)=>{
            return  <p
              style={{
                color: theme.color.blackColor,
                fontSize: "19px",
                fontWeight: "300",
                letterSpacing: "1px",
                margin: "9px 0",
              }}
            >
             &#x2022; {items?.name}
            </p> 
           })
        :
          <p
            style={{
              color: theme.color.blackColor,
              fontSize: "19px",
              fontWeight: "300",
              letterSpacing: "1px",
              margin: "9px 0",
            }}
          >
            {text}
          </p>
          }
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default VCAccordion;
