import Heading from 'library/Heading/index.js';
import React from 'react'
import * as El from './styled.appsec.js'
import  VCAccordion  from 'library/Accordion';
import { getImagePath } from 'utils';
import Image from 'library/Image';
import HoverZoomIn from 'components/Animation/HoverZoomIn/index.js';
import ScrollView from 'components/Animation/ScrollView/index.js';
import  useMedia  from 'hooks';

const  ApplicationSecurityTesting = () => {
  const isMobile = useMedia()
  const applyStyle = isMobile ? {
    fontSize: "27px",
    fontFamily: "Barlow",
    fontWeight: "800",
  } :{
    fontSize: "50px",
    fontFamily: "Barlow",
    fontWeight: "800",
  } 
  const data = [
    {
      heading: "Black Box Testing ",
      text: "In this type of testing the experts try to invade into a company’s system in a manner where the hacker would not have any advanced knowledge of the systems and networks involved in the company’s system.",
    },
    {
      heading: "Gray Box Testing",
      text: "In this type of testing, the experts invade a system with some semblance of information about the company’s internal systems and networks. Here a common method that is used  is a mixture of black-box testing and white box testing.",
    },
    {
      heading: "White Box Testing",
      text: " Here the experts perform VAPT from inside the    internal network of the company itself. This time the experts make  use of all knowledge about the systems and network architecture.",
    },
  ];

  return (
    <div>
      <El.ServiceContentWrapper>
          <div className="application-security-testing">
            <Heading
              style={applyStyle}
            >
              Application security testing
            </Heading>
            <div>
            <ScrollView>
            <El.AppSecParaghraph>
              The need for VAPT has been increasing with the exposed data and
              increased security threats. Be it a big enterprise or a Medium
              one, every business involved in a large data eco system must
              maintain security and this part requires the Penetration testing
              expertise.
            </El.AppSecParaghraph>
            </ScrollView>

              <HoverZoomIn scale={.9}>
            <El.ServiceTesting>
              <Image src={ getImagePath('/Testing.jpeg')} style={{borderRadius:"9%", heigh:"500px",width:"100%"}}/>
            </El.ServiceTesting>

            </HoverZoomIn>

            </div>
            <Heading
              style={applyStyle}
            >
              Vulnerability Assessment
            </Heading>
            <ScrollView>

            <El.AppSecParaghraph>
              This part aims at discovering and exposing the network of various
              web applications and the loopholes in its security.
            </El.AppSecParaghraph>
            </ScrollView>

            <Heading
               style={applyStyle}
            >
              Penetration Testing
            </Heading>
            <ScrollView>
            <El.AppSecParaghraph>
              Penetration Testing is a hacking attempt that aims to check how
              the system will fare in case of a real-time hacking. All the
              vulnerabilities that were exposed earlier are confirmed during the
              Penetration Testing. During this orchestrated malware infection
              and hacking attempt, we get a complete picture of how our existing
              infrastructure will act if it comes under seize.
            </El.AppSecParaghraph>
            </ScrollView>
            <div style={{ margin: "25px 0" }}>
              {data.map((items, idx) => {
                return <VCAccordion {...items} idx={idx} />;
              })}
            </div>
          </div>
        </El.ServiceContentWrapper>
    </div>
  )
}

export default  ApplicationSecurityTesting
