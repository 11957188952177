
import Heading from "library/Heading";
import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import  useMedia  from 'hooks';
import * as El from "../styled.Policies";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  const isMobile = useMedia()
  const applyStyle = isMobile ?
  {
    fontSize: "20px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } : {
    fontSize: "40px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } 
  return (
    <Container>
      <Row>
        <Col>
          <div style={{ marginTop: isMobile ? "12px" : "120px",display:"flex",alignItems:"center" }}>
            <Heading
              style={{ fontSize: isMobile ? "27px": "50px",
              fontFamily: "Barlow",
              fontWeight: "800"}}
            >
              Privacy Policy
            </Heading>
          </div>
          <El.PoliciesParagraph>
            Welcome to Venatic Solutions!
            <br/><br/>
            <Heading style={applyStyle}>
            Protecting Your Privacy
            </Heading>
            Protecting your privacy and maintaining confidentiality in relation to your personal information is something we take very seriously at Venatic Consulting. Our Privacy Policy is designed to ensure that we communicate to our clients, as clearly as possible, how we manage their personal information. Accordingly, we encourage you to read this policy very carefully and to contact us should you have any questions or concerns.

Venatic Consulting deals with your personal information and related privacy in accordance with the Privacy Act 1974. We reserve the right to amend or modify this Privacy Policy at any time however, an up-to-date copy of this document will always remain available on our firm’s website.
.
           <Heading style={applyStyle}>
           Personal Information
            </Heading>

            In order to provide the best possible services to you, Venatic Consulting needs to collect and store your personal information.

Personal information is any information which might identify you or by which your identity might reasonably be determined. Personal information collected by us may include, but is not limited to, your name, birthdate, place of residence, email address, telephone number, bank account details, superannuation details, accounting and financial information.

Whilst you can of course choose not to provide us with your personal information, We will be unable to provide you with services if you opt to do so.
           <Heading style={applyStyle}>
           How We Collect Your Personal Information
            </Heading>
            <br/>
            We will, whenever possible collect your personal information from you directly. Where we are unable to obtain personal information directly from you, we may request your consent, either written or verbal as appropriate, to obtain your personal information from a third party.

Ultimately, we may collect your personal information in several ways during our relationship with you, including the following:
<br/><br/>
                    

            &#10148; During personal conversations with you; <br/>
            &#10148; from documentation, hardcopy or digital media provided by you; <br/>
&#10148; from communications sent from you by fax, email, post, or other means; <br/>
&#10148; when you forward or upload electronic data to our office network; <br/>
&#10148; when you provide us with direct access to your electronic records or systems; <br/>
&#10148; from forms completed by you and returned to our office; <br/>
&#10148; from third parties when you have authorised us to do so; <br/>
&#10148; from event enrolment forms; <br/>
&#10148; when you visit our website; or <br/>
&#10148; when you connect with us using social media. <br/><br/>

It is possible also that we may collect personal information about you from other sources without your direct knowledge such as when funds are transferred from your bank account to our trust or general accounts and your bank account details are advised to us.

Regardless of how we collect your personal information, We will deal with your personal information in accordance with this privacy policy.
<Heading style={applyStyle}>
How We Use Your Personal Information
            </Heading>
            <br/>

            Venatic Consulting uses your personal information for the following purposes:<br/><br/>
            &#10148; to verify your identify;<br/>
&#10148; to contact and communicate with you;<br/>
&#10148; to provide you with agreed services;<br/>
&#10148; to notify you of any new or amended services offered by OHM relevant to the agreed service or otherwise;<br/>
&#10148; to assist you with technical support or support relating to the agreed service or otherwise;<br/>
&#10148; to carry out marketing initiatives relating to the services provided by OHM;<br/>
&#10148; to offer you additional services; and<br/>
&#10148; to comply with laws or regulations in relevant jurisdictions.<br/><br/>
When instructing us to provide an agreed service you consent to the collection, storage and use of your personal information as outlined in this policy and for any other use you may otherwise authorise. Venatic Consulting will only use your personal information for the provision of services to you and/or the purposes outlined in this Privacy Policy or as otherwise expressly authorised by you.<br/><br/>

Whenever possible, we will require you to specifically consent to any disclosure of your personal information to a third party where the disclosure of personal information is not in accordance with, or is unrelated to, the provision of services to you.<br/><br/>

In order to provide quality, efficient and affordable services to you, it is possible that your personal information may be viewed by third parties with whom Venatic Consulting has a vested relationship such as providers of auditing and bookkeeping services, audit insurance providers, accounting contractors, providers of financial services, IT contractors, and providers of software support. We may use Cloud Based Software during the analysis of your data and during the provision of some services, it is possible that your information may be reviewed by our overseas contractors.<br/><br/>

If at any time Venatic Consulting is required by law to release your personal information, we must cooperate fully.<br/><br/>

Once the provision of services to you is complete your personal information, both electronic and otherwise may be retained by Venatic Consulting for a period of seven years or more in the event that future instruction for the provision of additional or ongoing services may be forthcoming.<br/><br/>

        </El.PoliciesParagraph>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
