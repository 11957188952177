import { device } from "constant";
import styled from "styled-components";

const ExpertiesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ExpertiesMainWrapper = styled.div`
  padding-top: 30px;
`;

const ExpertiesLeftWrapper = styled.div`
  background-color: ${(props) => props.theme.color.greenColor};
  border-radius: 12px;
  margin-bottom: 20px;
  text-align: center;
  color: ${(props) => props.theme.color.body};
  font-family: "Barlow";
  @media ${device.mobileS} {
   padding: 60px 30px;
  }
  @media ${device.mobileM} {
    padding: 40px 30px;
  }
  @media ${device.mobileL} {

   padding: 40px 30px;
}
  @media ${device.tablet} {
      padding: 50px 30px;
   }
  @media ${device.tablet} {
    padding: 97px 30px;
  }
  @media ${device.laptop} {
    padding: 97px 30px;
  }
  @media ${device.laptopL} {
    padding: 97px 30px;
  }

  @media ${device.desktop} {
    padding: 97px 30px;
  }

  @media ${device.desktopL} {
    padding: 97px 30px;
  }
`;

const ExpertiesLeftContent = styled.div`
  font-family: "Barlow";
  cursor: pointer;
  h1 {
    font-size: 45px;
    font-weight: 800;
  }
  h2 {
    font-weight: 700;
    line-height: 30px;
    margin: 20px 0;
    color: ${(props) => props.theme.color.bgColor};
  }
  @media ${device.mobileS} {
    p {
      font-weight: 400;
      margin: 15px 0;
      font-size: 20px;
    }
  }
  @media ${device.mobileM} {
    p {
      font-weight: 400;
      margin: 15px 0;
      font-size: 17px;
    }
  }
  @media ${device.mobileL} {
    p {
      font-weight: 400;
      margin: 15px 0;
      font-size: 17px;
    }
  }
  @media ${device.tablet} {
    p {
      font-weight: 400;
      margin: 20px 0;
      font-size: 26px;
    }
  }
  @media ${device.laptop} {
    p {
      font-weight: 400;
      margin: 20px 0;
      font-size: 26px;
    }
  }
  @media ${device.laptopL} {
    p {
      font-weight: 400;
      margin: 20px 0;
      font-size: 26px;
    }
  }

  @media ${device.desktop} {
    p {
      font-weight: 400;
      margin: 20px 0;
      font-size: 26px;
    }
  }

  @media ${device.desktopL} {
   p{
      font-weight: 400;
      margin: 20px 0;
      font-size: 26px;
    }
  }
`;
const EnggModel = styled.div`
  div {
    color: ${(props) => props.theme.color.body};
    background-color: ${(props) => props.theme.color.lightGreenColor};
    border-radius: 12px;
    cursor: pointer;
    padding: 12px;
  }
  @media ${device.mobileS} {
    div {
    margin-top: 10px;
    }
  }

  @media ${device.mobileM} {
    div {
    margin-top: 10px;
    }
  }
  @media ${device.tablet} {
    div {
    margin-top: 10px;
    }
  }
`;
const ExpertiesRightWrapper = styled(EnggModel)`
  @media ${device.mobileS} {
    div {
      /* margin: 39px 0; */
    }
  }
  @media ${device.laptop} {
    div {
      margin-bottom: 60px;
      margin-left: 30px;
      box-shadow: 2px 10px ${(props) => props.theme.color.lightGreenColor};
    }
  }

  @media ${device.laptopL} {
    div {
      margin-bottom: 60px;
      margin-left: 30px;
      box-shadow: 2px 10px ${(props) => props.theme.color.lightGreenColor};
    }
  }

  @media ${device.desktop} {
    div {
      margin-bottom: 60px;
      margin-left: 30px;
      box-shadow: 2px 10px ${(props) => props.theme.color.lightGreenColor};
    }
  }
  @media ${device.desktopL} {
    div {
      margin-bottom: 60px;
      margin-left: 30px;
      box-shadow: 2px 10px ${(props) => props.theme.color.lightGreenColor};
    }
  }

  h2 {
    color: ${(props) => props.theme.color.bgColor};
    font-family: "Barlow";
    font-weight: 600;
  }
  p {
    font-family: "Barlow";
    font-weight: 400;
    font-size: 26px;
    font-family: 30px;
  }
`;
export {
   ExpertiesWrapper,
   ExpertiesLeftWrapper,
   ExpertiesRightWrapper,
   ExpertiesLeftContent,
   ExpertiesMainWrapper,
};
