import React from 'react'
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import  Heading  from 'library/Heading';
import { theme } from 'library/Theme/theme';
import Image from 'library/Image';
import { getImagePath } from 'utils';
import HoverZoomIn from 'components/Animation/HoverZoomIn';
import ScrollView from 'components/Animation/ScrollView';
import * as El from './styled.ustaxing'
import  useMedia  from 'hooks';
import VCAccordion from 'library/Accordion';

const UsTaxing = () => {
  const isMobile = useMedia()
  const applyStyle = isMobile ?
  {
    fontSize: "27px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } : {
    fontSize: "50px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } 
  const data = [
    {
      heading: "Pricing",
      pricing: 
        [
          { 
            name :"Federal and State -$75",
          },
          {
            name : "Federal and Multiple states - $100",
          },{
            name : "Schedule D - $40",
          },
          {
            name : " Schedule A - $50",
          },
          {
            name : "Schedule E -$40",
          },
          {
           name :  "Schedule C - $100 with expenses",
          },
          {
            name : " ITIN Application Each - $50"
          },
          {
            name : "FBAR & FATCA – FREE"
          }
        ]
    },
  ];

  return (
    <div>
      <Row>
            <Col xs={12} md={12} lg={6} xxl={6} className="d-none d-lg-block">
            <HoverZoomIn scale={1.2}>
              <ScrollView>
            <div style={{marginTop:"10rem", objectFit:"cover"}}>
             <Image src={getImagePath('/Tax-System.jpg')} style={{borderRadius:"50%",height:"50%",width:"80%"}} />
            </div>
            </ScrollView>

            </HoverZoomIn>
            </Col>
            <Col xs={12} md={12} lg={6} xxl={6}>
              <div>
                <Heading style={applyStyle}>
                  US Tax consulting
                </Heading>
                <ScrollView>

                <El.USParagraph>
                  VENATIC has expertise people with US Tax consulting
                  specifically with the individual tax preparation. 
                  <br/><br/>
                   Guidance
                  with various services relating to the Tax returns within the
                  IRS laws and terms.
                </El.USParagraph>
                </ScrollView>

                <Heading
                  style={applyStyle}
                >
                  Services
                </Heading>
                  <ScrollView>
                  <El.USParagraph>
                    &#10148; Federal & State e-filing terms.
                  </El.USParagraph>
                </ScrollView>

                <ScrollView>
                  <El.USParagraph>
                   &#10148;  ITIN Preparation
                  </El.USParagraph>
                </ScrollView>

                <ScrollView>
                <El.USParagraph>
                   &#10148;  FBAR & FATCA compliance
                </El.USParagraph>
                </ScrollView>


                <ScrollView>
                  <El.USParagraph>
                      &#10148;  Amendment  filing
                  </El.USParagraph>
                </ScrollView>

                <ScrollView>
                  <El.USParagraph>
                  &#10148;  Audit Representation 
                  </El.USParagraph>
                </ScrollView>

                <ScrollView>
                <El.USParagraph>
                   &#10148;  Extension filings 
                   <br/>
                </El.USParagraph>
                </ScrollView>
                
                <div style={{ margin: "25px 0" }}>
                  {data.map((items, idx) => {
                    return <VCAccordion {...items} idx={idx} />;
                  })}
                </div>

                <i style={{color:theme.color.purpleColor}}> Note: Venatic Consulting focuses on IRS
                  laws and terms to give an appropriate service to our clients ,
                  we work on a long term basis.</i>

              </div>
            </Col>
          </Row>
    </div>
  )
}

export default UsTaxing
