import styled from "styled-components";

const VCInput = styled.input`
    padding: 15px 10px;
    width: 100%;
    margin: 12px 0;
    outline: none;
    border: 2px solid ${(props)=> props.error ? "red" : props.theme.color.greenColor};
    border-radius: 8px;
    color:black
`

export { VCInput }