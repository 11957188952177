export const theme = {
	color: {
		body: '#fff',
		fontColor: "#faf7f7",
		bgColor: "#262223",
		greenColor :'#48ab62',
		lightGreenColor:'#50c878',
		blackColor: '#000',
		greyColor:"#f6f6f6",
		purpleColor:'#213091'
	}
};