import { device } from "constant";
import styled  from "styled-components";
const CardWrapper = styled.div`
    background-color: ${(props) => props.theme.color.greyColor};
    border-radius: 5px;
    text-align: center;
    padding: 19px;
    cursor: pointer;
`
const CardImageWrapper = styled.div`
    border-radius: 50%;
    margin: 1.3rem;
    margin-left: auto;
    margin-right: auto;
`

const CardTitleWrapper = styled.div`

`
const CardTitle = styled.h1`
  font-weight: 700;
  color: ${(props) => props.theme.color.greenColor};
  @media ${device.mobileS}{
      font-size : 20px;
   }
  @media ${device.mobileM}{
        font-size : 20px;
   }

  @media ${device.mobileL}{
        font-size : 20px;
   }
   font-size : 25px;
`

const CardContent = styled.div`
	font-family: 'Barlow';
   font-weight: 400;
   @media ${device.mobileS}{
    p{
      font-size : 17px;
    }
   }
  @media ${device.mobileM}{
     p{
        font-size : 17px;
     }
   }

  @media ${device.mobileL}{
     p{
        font-size : 17px;
     }
   }
@media ${device.tablet}{
    p{
      font-size : 17px;
    }
   }
  @media ${device.laptop}{
     p{
        font-size : 20px;
     }
   }

  @media ${device.laptopL}{
     p{
        font-size : 20px;
     }
   }

  @media ${device.desktop}{
     p{
        font-size : 20px;
     }
   }

  @media ${device.desktopL}{
     p{
        font-size : 20px;
     }
   }
`

export { CardContent, CardWrapper, CardImageWrapper, CardTitleWrapper, CardTitle }