import { device } from "constant";
import styled from "styled-components";

const AboutWrapper = styled.div`
  @media ${device.mobileS}{
  }
  @media ${device.laptop}{
   margin: 2rem 0 10rem 0;
  }
`
const AboutContentWrapper = styled.div `
`

export { AboutWrapper, AboutContentWrapper }