import React from 'react'
import * as El from './styled.banner';
import Carousel from 'components/Carousel';


const Banner = () => {
  return (
    <El.BannerWrapper>
      <Carousel/>
    </El.BannerWrapper>
  )
}

export default Banner
