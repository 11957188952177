import { device } from "constant";
import styled from "styled-components";

const OurApproachWrapper = styled.div`
  margin: 12px 0;
`
const ImageWrapper = styled.div`

`
const RightContentWrapper =styled.div`
 text-align: center;
 margin: 12px 0;
`
const OurApproachContentWrapper = styled.div`
    @media ${device.mobileS}{
      margin: 2rem 0 3rem  0;
    }
    @media ${device.mobileL}{
      margin: 2rem 0 3rem  0;
    }
    @media ${device.mobileM}{
      margin: 2rem 0 3rem  0;

    }
    @media ${device.tablet}{
      margin: 2rem 0 3rem  0;
    }
    @media ${device.laptop}{
    margin: 2rem 0 6rem  0;
    }
    @media ${device.laptopL}{
      margin: 2rem 0 6rem  0;
    }
    @media ${device.desktop}{
      margin: 2rem 0 6rem  0;

    }
    @media ${device.desktopL}{
      margin: 2rem 0 6rem  0;
   }
`
const RightContent = styled.div`
    background-color: ${(props) => props.theme.color.greyColor};
    border-radius: 12px;
    text-align: left;
    h1 {
      font-family: 'Barlow';
      font-weight: 800;
      color:${(props) => props.theme.color.bgColor};
      line-height: 4rem;
      padding: 12px 9px;
    }
    @media ${device.mobileS}{
      h1{
        font-size: 20px;
      }
    }
    @media ${device.mobileS}{
       font-size: 20px;
    }

`
export { OurApproachWrapper , ImageWrapper , OurApproachContentWrapper, RightContentWrapper, RightContent}