import styled  from "styled-components";
import { device } from 'constant';


const WrapperImage = styled.div`
  position: relative;
  :before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.4);
 }
`
const CarouselContentWrapper = styled.div`
    position:absolute; 
    font-weight:800; 
    transform: translate(-50%, -50%);
    top:40%;
    left:50%;
    text-align:center;
    @media ${device.mobileS} {
      margin: 12px 0;
    }
`
const CarouselBusinessContentWrapper = styled.div`
        color: ${(props)=> props.theme.color.body} ;
        font-family:Barlow;
        font-weight:500;
`
const CouruselData = styled.h1`
        color: ${(props)=> props.theme.color.body} ;
        font-family: Barlow;
        font-weight: 500;
`
const CarouselContentH1 =styled(CouruselData)`
        @media ${device.mobileS} {
          font-size: 1rem;  
        }
        @media ${device.mobileM} {
          font-size: 1.3rem;  
        }
        @media ${device.mobileL} {
          font-size: 1.6rem;  
        }
        @media ${device.tablet} {
          font-size: 1.8rem;  
        }
        @media ${device.laptop}{
          font-size: 4.5rem;
        }
        @media ${device.desktop}{
          font-size: 4.8rem;
        }
        @media ${device.desktopL}{
          font-size: 5rem;
        }
`
const CarouselContentSpan = styled.span`  
      color: ${(props)=> props.theme.color.greenColor} ;
      font-family:Barlow;
      margin-top:3rem;
` 
const ButtonWrapper = styled.div`
        margin-top: 5rem;
        @media ${device.mobileS}{
           margin-top: 1rem;
        }
        @media ${device.mobileM}{
           margin-top: 1.3rem;
        }
        @media ${device.mobileL}{
           margin-top: 2rem;
        }
        @media ${device.tablet}{
          margin-top: 4rem;
        }
`

export { WrapperImage, CarouselContentWrapper,ButtonWrapper,CarouselContentSpan, CarouselBusinessContentWrapper,CarouselContentH1}