import { useState } from "react";
import { routeConstants } from "constant";
import { Route, Routes } from "react-router-dom";
import Navbar from "components/Navbar";
import ContactUs from "pages/ContactUs";
import OurClients from "pages/OurClients";
import Services from "pages/Services";
import Home from "./pages/Home/index";
import "./App.css";
import Footer from "components/Footer";
import Loader from "components/Loader";
import PrivacyPolicy from "pages/Policies/Privacy Policy";
import RefundPolicy from "pages/Policies/Refund Policy";
import TermsConditons from "pages/Policies/TandC";
function App() {
  const [loader, setLoader] = useState(true);
  setTimeout(() => {
    setLoader(false);
  }, 2000);

  return (
    <div style={{ overflow: "hidden" }}>
      {loader ? (
        <div style={{padding: "12rem 0", textAlign: "center"}}>
          <Loader/>
        </div>
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path={routeConstants.HOME} element={<Home />} />
            <Route path={routeConstants.OUR_CLIENTS} element={<OurClients />} />
            <Route path={routeConstants.SERVICES} element={<Services />} />
            <Route path={routeConstants.CONTACT_US} element={<ContactUs />} />
            <Route path={routeConstants.Privacy_Policy} element={<PrivacyPolicy />} />
            <Route path={routeConstants.Refund_Policy} element={<RefundPolicy />} />
            <Route path={routeConstants.Terms_Conditions} element={<TermsConditons />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
