import { theme } from "library/Theme/theme";
import React from "react";
import { getImagePath } from "utils";
import * as El from "./styled.ourmission";
import { Row, Col } from "react-bootstrap";
import Image from "library/Image";
import EaseOut from "components/Animation/EaseOut";
import ScrollView from "components/Animation/ScrollView";
import  useMedia  from 'hooks';
const OurMission = () => {
  const isMobile = useMedia()
  const desktopStyleh1 = {color:theme.color.blackColor, fontWeight:"200",letterSpacing:"3px",lineHeight:"40px"}
  const mobileStyleh1 = {fontSize:"14px", color:theme.color.blackColor, fontWeight:"200", letterSpacing:"2.5px",lineHeight:"23px",marginBottom:"12px"}
  const appplyStyleH1 = isMobile ?  mobileStyleh1 : desktopStyleh1

  return (
    <El.MainOurMissionWraper style={{ marginTop: !isMobile && "-100px", marginBottom:isMobile ? "20px" : ""}} >
    <Row style={{height:"470px"}}>
      <Col xs={12} md={12} lg={6} xl={6} className="mt-lg-5">
        <EaseOut>
          <El.OurMissionWrapper>
            <El.OurMissionHeading>
              {" "}
              Our Mission /{" "}
              <span style={{ color: theme.color.greenColor }}>
                {" "}
                Vision{" "}
              </span>{" "}
            </El.OurMissionHeading>
            <El.ContentWrapper>
              <El.TopContent></El.TopContent>
              <ScrollView>
              <El.BottomContent>
               <h3 style={appplyStyleH1}>
                  Committing a step in offering end to end consulting services
                  with {" "}
                <span
                style={{color:theme.color.greenColor,fontWeight:"700",letterSpacing:"3px",lineHeight:"40px"}}
                >
                 Design, Process and Manage </span>  across various platforms.
              </h3>
              </El.BottomContent>
              </ScrollView>

            </El.ContentWrapper>
          </El.OurMissionWrapper>
        </EaseOut>
      </Col>
      <Col className="mt-lg-5">
         <ScrollView>
          <div style={{marginTop: isMobile ? "40px" :"60px", marginBottom:isMobile ? "40px" :"60px"}}>
         <Image src={getImagePath("/banner.jpg")}  style={{heigh:"500px",width:"100%"}}/>
         </div>
        
        </ScrollView>
      </Col>
    </Row>
    </El.MainOurMissionWraper>

  );
};

export default OurMission;
