
import Heading from "library/Heading";
import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import  useMedia  from 'hooks';
import * as El from "../styled.Policies";

const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  const isMobile = useMedia()
  const applyStyle = isMobile ?
  {
    fontSize: "20px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } : {
    fontSize: "40px",
    fontFamily: "Barlow",
    fontWeight: "800",
    margin: "2rem 0",
  } 
  return (
    <Container>
      <Row>
        <Col>
          <div style={{ marginTop: isMobile ? "12px" : "120px",display:"flex",alignItems:"center" }}>
            <Heading
              style={{ fontSize: isMobile ? "27px": "50px",
              fontFamily: "Barlow",
              fontWeight: "800"}}
            >
              Refund Policy
            </Heading>
          </div>
        <El.PoliciesParagraph>
            Welcome to Venatic Solutions!
            <br/><br/>
           <Heading style={applyStyle}>
           Refunds/Cancellation
            </Heading>
            <br/>
            At Venatic Consulting, we have full confidence in our varied range of services, products and e-business solutions. We leave no stone unturned in providing our esteemed clients fast, reliable and exceptional service guarantee every time in time we do business with them. All the services available at our website are carried out for the clients after extensive project analysis using a complete scope document. It ensures full understanding of the work and almost no possibilities of any project cancellation, reversal or dispute. However, any refund and service cancellation is taken place abiding a set of conditions.
            <br/><br/>
           

            <Heading style={applyStyle}>
            Cancellation Policy
            </Heading>
            <br/>

            A subscriber can place the cancellation order for a particular service within 36 Hours of service agreement via prescribed cancellation form available on our website.
No cancellation is possible for the services offered with promotional discounts on special occasions viz. Independence Day, Christmas and New Year. These are limited edition offers and hence any range of refund would not be possible.
In no case, Venatic Consulting will entertain cancellation requests made for services such as domain name registration and domain name transfer.
Fee once paid will not be refunded in any case even if the cancellation is posted within 12 hours.
A service stands cancel if next payment is not received within 7 working days

            <br/><br/>
            <Heading style={applyStyle}>
            Refund Policy
            </Heading>
            <br/>

            
            <br/>


            Every service offered by us takes different course of action to get accomplished from start to end thus refund policy differs from one service to other.
There is always a certain set of resources that are involved in achieving every milestone of a campaign. Thus, a refund would not be entertained for the work already completed.
No refund is possible for the fee paid in the form of administrative fees or installation charges.
Taxes paid on services levied by regulatory bodies would not be refunded with fee refund.
No refund will be entertained in case service gets cancelled due to any violation of terms stated in our Terms & Condition section.

            <br/>
             
        </El.PoliciesParagraph>
        </Col>
      </Row>
    </Container>
  );
};

export default RefundPolicy;
