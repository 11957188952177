import { theme } from "library/Theme/theme";
import React from "react";
import * as El from "./styled.ourapproach";
import { Row,Col } from "react-bootstrap";
import { getImagePath } from "utils";
import  Image  from 'library/Image';
import HoverZoomIn from "components/Animation/HoverZoomIn";
import ScrollView from "components/Animation/ScrollView";
import { OurMissionHeading } from "../OurMission/styled.ourmission";

const OurApproach = () => {
  return (
    <El.OurApproachWrapper>
      <OurMissionHeading>
        Our <span style={{ color: theme.color.greenColor }}>Approach</span>
        </OurMissionHeading>
      <El.OurApproachContentWrapper>
      <Row>
        <Col xs={12} md={12} lg={6} xxl={6}>
        <HoverZoomIn scale={.9}>
          <ScrollView>
          <El.ImageWrapper >
              <Image src={getImagePath("/girl.jpg")} style={{borderRadius:"20px",heigh:"700px",width:"100%"}} />
          </El.ImageWrapper>
        </ScrollView>
        </HoverZoomIn>
        </Col>
        <Col>
        <ScrollView>
          <El.RightContentWrapper>
         <HoverZoomIn scale={1.2}>
            <El.RightContent>
              <h1 className="mt-0 pt-0">
                  Client / Customer Collaboration
              </h1> 
            </El.RightContent>
            </HoverZoomIn>
         <HoverZoomIn scale={1.2}>
            <El.RightContent>
              <h1> 
               Hybrid Teams
                </h1> 
            </El.RightContent>
            </HoverZoomIn>
            <HoverZoomIn scale={1.2}>

            <El.RightContent>
              <h1>
               Implementing Speed & Quality
                </h1>
                 </El.RightContent>
            </HoverZoomIn>
            <HoverZoomIn scale={1.2}>

            <El.RightContent>
              <h1> 
               Embrace new ideas & Solutions
                </h1>
            </El.RightContent>
            </HoverZoomIn>

          </El.RightContentWrapper>
          </ScrollView>
        </Col>
      </Row>
      </El.OurApproachContentWrapper>
    </El.OurApproachWrapper>
  );
};

export default OurApproach;
