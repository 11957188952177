import { Row, Col } from "react-bootstrap";
import { theme } from "library/Theme/theme";
import React from "react";
import * as El from "./styled.about";
import VCCard from "library/VCCard";
import { getImagePath } from 'utils';
import HoverZoomIn from "components/Animation/HoverZoomIn";
import ScrollView from "components/Animation/ScrollView";
import { OurMissionHeading } from "../OurMission/styled.ourmission";

const About = () => {
  return (
    <El.AboutWrapper>
      {/* <Heading style={{ fontSize: "40px", fontFamily:"Barlow", fontWeight:"600" }}> */}
      <OurMissionHeading>
        About{" "}
        <span style={{ color: theme.color.greenColor }}>
          {" "}
          Venatic Consulting{" "}
        </span>{" "}
      </OurMissionHeading>
      <El.AboutContentWrapper>
      <Row>
        <Col xl={12} md={12} lg={4} xxl={4} className="mt-5">
        <HoverZoomIn scale={1.2}>
          <ScrollView>
            <VCCard text=" 
              Venatic Consulting is a start-up pioneer into the IT outsourcing and
              Consulting Services adapting a new environment on customer engagement
              model to accurate the end user experience.
            " 
            header="Our Business"
            src={getImagePath('/businessman.svg')}
            />
          </ScrollView>

        </HoverZoomIn>
          
        </Col>

        <Col xl={12} md={12} lg={4} xxl={4} className="mt-5">
        <HoverZoomIn scale={1.1}>
        <ScrollView>

        <VCCard text=" 
            Venatic Boasts a team of diverse background & expertise which
            consistently delivers results.
          " 
          header="Stretagic Planning"
          src={getImagePath('/plan.svg')}

          />
          </ScrollView>
      </HoverZoomIn>          
        </Col>

        <Col xl={12} md={12} lg={4} xxl={4} className="mt-5">
        <HoverZoomIn scale={1.1}>
        <ScrollView>
          
        <VCCard text=" 
                We are an innovative team to strive in a competitive world, having
                corporate social responsibility which makes a sustainable growth for
                our Clients, Partners & Associates across the globe.
          " 
          header="What we are?"
            src={getImagePath('/weare.svg')}
          />
          </ScrollView>
          </HoverZoomIn>
        </Col>
      </Row>
      </El.AboutContentWrapper>
    </El.AboutWrapper>
  );
};

export default About;
