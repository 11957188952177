import React ,{ useState }  from 'react'
import Button from "library/Button";
import Image from 'library/Image';
import { getImagePath } from 'utils';
import Heading from 'library/Heading';
import * as El from "./styled.application.js";
import { Row, Col } from "react-bootstrap";
import  HoverZoomIn  from 'components/Animation/HoverZoomIn';
import  ScrollView  from 'components/Animation/ScrollView';
import  useMedia  from 'hooks';


const ApplicationDevAndMentenance = () => {
  const [seeMore, setSeeMore] = useState(false);
  const isMobile = useMedia();
  const applyStyle = isMobile ?{
    fontSize: "27px",
    fontFamily: "Barlow",
    fontWeight: "800",
    marginTop:"32px"
  }  : {
    fontSize: "50px",
    fontFamily: "Barlow",
    fontWeight: "800",
  }
  return (
    <div>
      <Row style={{ padding: isMobile ? "" :"30px", margin: isMobile ?  "30px 0" : "70px 0", fontFamily: "Barlow" }}>
          <Col xs={12} md={12} lg={6}>
          <HoverZoomIn scale={1.2}>
          <ScrollView>
            <div>
              <Image
                src={getImagePath("/ADM.png")}
                style={{ height: "90%", width: "90%" }}
              />
            </div>
            </ScrollView>
            </HoverZoomIn>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <Heading
              style={applyStyle}
            >
              Application development & maintenance
            </Heading>
            <ScrollView>
            <El.AppDevParagraph>
              In this competitive market, it requires the enterprise to design
              and manage the application. We collaborate with you to understand
              your business, processes and applications. Our application
              development and maintenance solution drive efficiency and
              effectiveness which help you to build adaptable platform for new
              social and other enterprises. By redesigning applications,
              enterprises can become agiler while enriching customer experiences
              in a secure environment. We collaborate with you to understand
              your business, processes and applications.
            </El.AppDevParagraph>
            </ScrollView>
            <div style={{ margin: "19px 0" }}>
              <Button onClick={() => setSeeMore(!seeMore)}>
                {" "}
                {seeMore ? "See Less" : "See More"}
              </Button>
            </div>
            {seeMore && (
              <El.BottomADMContentWrapper>
                <El.BottomADMContent>
                  {" "}
                  How Our Application Services helps you
                </El.BottomADMContent>
                <El.BottomADMContentData>
                  {" "}
                  - To Increase the value of applications.
                </El.BottomADMContentData>
                <El.BottomADMContentData>
                  {" "}
                  - Provide application resiliency.
                </El.BottomADMContentData>
                <El.BottomADMContentData>
                  {" "}
                  - Application Simplification.
                </El.BottomADMContentData>
                <El.BottomADMContentData>
                  {" "}
                  - Adopt next generation applications.
                </El.BottomADMContentData>
                <El.BottomADMContentData>
                  {" "}
                  - Bring innovation to the forefront.
                </El.BottomADMContentData>
              </El.BottomADMContentWrapper>
            )}
          </Col>
        </Row>
       </div>
  )
}

export default ApplicationDevAndMentenance
