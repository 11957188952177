import { device } from "constant";
import styled from "styled-components";

const BannerWrapper = styled.div`

@media ${device.mobileS} {
}
`
const ImageWrapper = styled.div`
    width: 100%;
    height: auto;
`
const CCParaghraph = styled.div`

`
export {  BannerWrapper ,ImageWrapper, CCParaghraph }
