import Image from 'library/Image'
import React from 'react'
import * as El from './styled.footer'
import { getImagePath } from 'utils';
import LinkTag from 'library/Link';
import { Row,Col } from 'react-bootstrap';
import { routeConstants } from 'constant';

const Footer = () => {
  return (
    <El.FooterMainWrapper >
      <El.FooterContent>
          <Row className='p-5'>
          <Col lg={4}>
          <El.FooterLeftContent>
        <LinkTag route={`${routeConstants.HOME}`}>
              <Image src={getImagePath('/logo.png')} style={{height: "60px",width:"60px"}} />
             <h1> 
             Venatic Consulting
            </h1>
        </LinkTag>
        <div>{" "}</div>
        </El.FooterLeftContent>
          </Col>
          
          <Col lg={4}>
          <El.FooterMiddleContent>
            <h1>Our services</h1>
            <p>&#8226; US Tax Consulting Services </p>
            <p>&#8226; Application Development & Maintenance </p>
            <p>&#8226; Application Security Testing </p>
            <p>&#8226; OutSourcing </p>
            
        </El.FooterMiddleContent>
          </Col>
        
          <Col lg={4}> 
              <El.FooteRightContent>
              <h1>Contact info</h1>
              <p> Email : {" "} support@venaticconsulting.com</p>

              <div style={{display:"flex"}}>
                  <div>
                  <p> Phone :</p>
                 </div>

                 <div style={{marginLeft:"7px"}}>
                  <p>+1 (904) 606-4084 US </p>
                  <p> +91 9989056977   IN  </p>
  
                </div>
                

              </div>
              <p> Address : {" "}  D.No 1-65/469, PM&BS Square, Avenue 1, Kakatiya Hills, Madhapur, Telangana 500081</p>
              </El.FooteRightContent>
          </Col>
          </Row>
        </El.FooterContent>

    <El.FooterWrapper>
        <div> 
            <p style={{color:"white"}}>Copyright © 2023 All Rights Reserved by Venatic Consulting Pvt ltd.</p>
            {/* <a href="http://www.freepik.com">Designed by pch.vector / Freepik</a> */}
        </div>
        <div>
        <p style={{color:"white"}}>
          <span>
            <LinkTag route={`${routeConstants.Terms_Conditions}`}>
              <span> Terms & conditions </span> 
            </LinkTag>
            |
            <LinkTag route={`${routeConstants.Privacy_Policy}`}>
              <span> Privacy Policy </span> 
            </LinkTag>
            |
            <LinkTag route={`${routeConstants.Refund_Policy}`}>
              <span> Refund Policy </span>
            </LinkTag>
          </span>
        </p>
        </div>
        <div>
        <p style={{color:"white"}}>Connect with us:</p>
        <div style={{ display:"flex"}}>
            <div style={{ height:'30px',marginRight:"12px",cursor:"pointer"}}>
              <Image src={getImagePath('/facebook.svg')}/>
            </div>
            <div style={{height:'30px', cursor:"pointer"}}>
            <Image src={getImagePath('/linkedin.svg')}/>
            </div>
        </div>
        </div>

    </El.FooterWrapper>
    </El.FooterMainWrapper>

)
}

export default Footer
